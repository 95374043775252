<template>
  <div v-if="user">
    <b-row>
    <b-col  cols="12"
      v-if="$can('manage', 'admin')"
    >
      <div>
        PE firm
          <b-form-group>
          <v-select
            v-model="parent"
            label="name"
            :options="parentCompanies"
          />
        </b-form-group>
      </div>
    </b-col>

    <b-col  cols="12"
      v-if="$can('read', 'parent')"
    >
      <div>
        <div v-if="user.role=='parent'">Company</div>
        <div v-else>Portfolio Company</div>
          <b-form-group>
          <v-select
            v-model="child"
            label="name"
            :options="childCompanies"
          />
        </b-form-group>
      </div>
    </b-col>

  </b-row>
    <b-row v-if="child">
         <b-col cols="12">
              <!-- <b-card-code title="Basic Table"> -->
                <!-- table -->

                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                    </div>
                </b-form-group>
                </div>
    
                <vue-good-table
                :columns="columns"
                :rows="rows"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm }"
                :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                }"
                >
                <template
                    slot="table-row"
                    slot-scope="props"
                >
                    <!-- Column: Name -->
                    <span
                    v-if="props.column.field === 'fullname'"
                    class="text-nowrap"
                    >
                    <b-avatar
                        :src="props.row.avatar"
                        class="mx-1"
                    />
                    <span class="text-nowrap">{{ props.row.name.first }} {{ props.row.name.last }}</span>
                    </span>

                    <span
                    v-if="props.column.field === 'phone'"
                    class="text-nowrap"
                    >
                      {{ getFormattedNumber(props.row.phone) }}
                    </span>

                    <!-- Column: Status -->
                    <!-- <span v-else-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status)">
                        {{ props.row.status }}
                    </b-badge>
                    </span> -->

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                    <span>
                        <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        >
                        <template v-slot:button-content>
                            <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                            />
                        </template>
                        
                        <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }"> -->
                        <b-dropdown-item :href="getEditLink(props.row._id)">
                            <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                            />
                            <span>Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteUser(props.row)">
                            <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                            />
                            <span>Delete</span>
                        </b-dropdown-item>
                        </b-dropdown>
                    </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                        Showing 1 to
                        </span>
                        <b-form-select
                        v-model="pageLength"
                        :options="['5','10','15','20']"
                        class="mx-1"
                        @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>
                    </div>
                    </div>
                </template>
                </vue-good-table>
            <!-- </b-card-code> -->

        </b-col>
    </b-row>
    </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BRow, BCol, BCard, BCardBody
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from 'vue-select'
import AxiosService from '@/Service/AxiosService'
import { off } from 'process'
import { isErrored } from 'stream'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    // BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    vSelect
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Full Name',
          field: 'fullname',
        },
        {
          label: 'Job Title',
          field: 'jobtitle',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      parentCompanies:[],
      childCompanies:[],
      parent: null,
      child: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    // direction() {
    //   if (store.state.appConfig.isRTL) {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.dir = true
    //     return this.dir
    //   }
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   this.dir = false
    //   return this.dir
    // },
    
  },
  mounted(){
    this.$store.dispatch("getUser");
  },  
  methods:{
    init(){
      // console.log("user role", this.user.role)
      if(this.user.role == "admin")
        this.getParentCompany()
      else if(this.user.role=='parent')
        this.parent = this.user.entity;
      
      else this.child = this.user.entity;
      
        //get parent companies

      //when parent selected, get child companies + self
    },
    getParentCompany(){      
      AxiosService.get("/companies", {type:"parent"}).then(({error, message, data})=>{
          if(!data){ 
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    variant: 'error',
                    text: `Some error occured while getting companies, Please try again or contact an admin`,
                },
            })
            return;
          }
          // if(data.rows) companies.rows = data.rows;
          if(data.rows){
            // console.log("parent company", data)
            this.parentCompanies = data.rows;
            this.parent = this.parentCompanies[0]
          }
      })
    },
    getChildCompany(){ 
      if(!this.parent) return;

      AxiosService.get("/companies", {type:"child", parent:this.parent._id}).then(({error, message, data})=>{
          if(!data){
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    variant: 'error',
                    text: `Some error occured while getting companies, Please try again or contact an admin`,
                },
            })
            return;
          } 
          // if(data.rows) companies.rows = data.rows;
          if(data.rows){
            this.childCompanies = data.rows;
            let self = JSON.parse(JSON.stringify(this.parent));
            // self.name = "SELF";
            this.childCompanies.unshift(self)
            this.child = this.childCompanies[0]
          }
      })
    },
    getUsers(){
      if(!this.child) return;
      AxiosService.get("/users", {entity:this.child._id}).then(({error, message, data})=>{
          this.rows = [];

          if(!data){
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    variant: 'error',
                    text: `Some error occured while getting users, Please try again or contact an admin`,
                },
            })
            return;
          }
          // if(data.rows) companies.rows = data.rows;
          if(data.rows){
            this.rows = data.rows;
            // let self = JSON.parse(JSON.stringify(this.parent));
            // self.name = "SELF";
            // this.childCompanies.unshift(self)
            // this.child = this.childCompanies[0]
          }
      })
    },
    deleteUser(user){
      this.$swal({
        title: 'Warning!',
        text: `Are you sure you want to delete ${user.name.first} ${user.name.last}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Delete ${user.name.first} ${user.name.last}`,
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      }).then(result => {
        // console.log("result", result)
        if(!result.isConfirmed) return;
        
        AxiosService.post("/deleteUser", {id:user._id}).then(({error, data})=>{
          if(error) console.log("error");
          else console.log("deleted");

          this.getParentCompany();
        })
      })
    },
    getFormattedNumber(number){
      // console.log("number", number)
      let cleaned = ('' + number).replace(/\D/g, '');
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        // console.log("result", '(' + match[1] + ') ' + match[2] + '-' + match[3])
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
      return null;
    },
    getEditLink(id){
      return `/users-edit?id=${id}`
    }
  },
  watch:{
    parent(){
        this.getChildCompany();
    },
    child(){
        this.getUsers();
    },
    user(newVal){
      // console.log("user watch")
      if(newVal)
        this.init();
    }
  },
  computed:{
    user(){
        return this.$store.getters.getUser || {};
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>